// src/App.tsx
import React from "react";
import AppRouter from "./Router";
import { AudioProvider } from "context/SoundContext";
import { GlobalProvider } from "./context/GlobalContext";

export const App: React.FC = () => {
  console.log("Rendering App");
  return (
    <GlobalProvider>
      <AudioProvider>
          <AppRouter />
      </AudioProvider>
    </GlobalProvider>
  );
};
