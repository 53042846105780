import { SessionStep } from "types.d";
import { AudioEngine } from "../audio/AudioEngine";
import React, { createContext, useCallback, useContext, useMemo } from "react";

interface SoundContextProps {
  audioEngines: AudioEngine[];
  currentEngineId?: number;
  setCurrentEngineId?: (id: number) => void;
  getEngineForStep: (step: SessionStep) => AudioEngine | undefined;
}

const SoundContext = createContext<SoundContextProps>({
  audioEngines: [],
  getEngineForStep: () => new AudioEngine(0),
});

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const audioEngines = useMemo(() => {
    return [new AudioEngine(0), new AudioEngine(1)];
  }, []);

  const getEngineForStep = useCallback(
    (step: SessionStep) => {
      if (step <= SessionStep.FORCE_STOP) {
        return undefined;
      }

      const mod = step % audioEngines.length;
      console.log("Getting engine for step", step, audioEngines[mod]);
      return audioEngines[mod];
    },
    [audioEngines]
  );

  return (
    <SoundContext.Provider
      value={{
        audioEngines,
        getEngineForStep,
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};

export const useAudio = () => useContext(SoundContext);
