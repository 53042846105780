import { useEffect } from "react";
import { SessionView } from "./SessionView";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "context/GlobalContext";

export const SessionPage: React.FC = () => {
  const { session, saveSession } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!session.therapist) {
      navigate("/");
      return;
    }

    if (!session.space) {
      navigate("/space");
      return;
    }

    if (session.issues.length === 0) {
      navigate("/issues");
      return;
    }

    if (!session.length) {
      navigate("/length");
      return;
    }
    
    saveSession(session);
  }, [session, navigate, saveSession]);

  return <SessionView />;
};
