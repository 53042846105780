import { Page } from "./Page";
import { SessionStep } from "types.d";
import { Button } from "../components";
import { Pause, Play } from "@phosphor-icons/react";
import { SessionLog } from "../components/SessionLog";
import { SessionContext } from "context/SessionContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { SessionDebug } from "../components/SessionDebug";

export const SessionView: React.FC = () => {
  const [debug, setDebug] = useState<boolean>(false);
  const { running, setRunning, current, setCurrent } = useContext(SessionContext);

  useEffect(() => {
    // Scroll to top
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  useEffect(() => {
    console.log("SessionView dependency update, running effect, setting running to true");
    setRunning(true);
    setCurrent(SessionStep.IDLE);

    return () => {
      console.log("SessionView dependency update, running cleanup");
      console.log("Just set step to FORCE_STOP and running to false");
      setCurrent(SessionStep.FORCE_STOP);
      setRunning(false);
    };
  }, [setRunning, setCurrent]);

  const _toggleTransport = useCallback(() => {
    setCurrent(SessionStep.IDLE);
    setRunning(!running);
  }, [running, setRunning, setCurrent]);

  return (
    <Page>
      {debug && <SessionDebug onClose={() => setDebug(false)} />}

      {!debug && (
        <div className="flex flex-col flex-1 items-center p-4">
          <SessionLog />
          <div className="flex gap-4">
            <Button size="small" variant="primary" onClick={_toggleTransport}>
              {running ? <Pause weight="fill" /> : <Play weight="fill" />}
            </Button>

            <Button size="small" variant="secondary" onClick={() => setDebug(true)}>
              Debug
            </Button>
          </div>
        </div>
      )}
    </Page>
  );
};
