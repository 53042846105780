export const SimpleAudio: React.FC<{ path: string }> = ({ path }) => {
  return (
    <li className="my-3 text-zinc-200">
      <audio controls className="mb-1">
        <source src={path} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <a href={path} target="_blank" className="text-xs hover:underline" rel="noreferrer">
        {path}
      </a>
    </li>
  );
};
