import { Button } from "./Button";
import { Overview } from "./Overview";
import { ISessionConfig } from "types";
import { Fragment, useCallback } from "react";
import { useGlobal } from "context/GlobalContext";

export const SavedSessions = () => {
  const { savedSessions, session, setSession } = useGlobal();

  const _selectSession = useCallback(
    (session: ISessionConfig) => {
      setSession({ ...session, active: true, isRepeat: true });
    },
    [setSession]
  );

  if (savedSessions.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <div className="px-4 flex flex-col gap-8 pb-12">
        <Button onClick={() => setSession({ ...session, active: true })}>
          Start a new session
        </Button>

        <div>
          <h2 className="text-xl text-ph-pink mb-4">Previous sessions</h2>
          <div className="flex gap-4 flex-col">
            {savedSessions.map((session, i) => {
              return (
                <Overview key={i} session={session} variant="card">
                  <Button onClick={() => _selectSession(session)}>
                    Repeat this session
                  </Button>
                </Overview>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
