import { InputGroup } from "./InputGroup";
import { TherapistCard } from "./TherapistCard";
import { SelectorProps, ITherapist } from "types";
import { useGlobal } from "context/GlobalContext";
import { PreviewContextProvider } from "PreviewContext";
import { useMemo } from "react";

export const TherapistSelector: React.FC<SelectorProps> = () => {
  const { config, session } = useGlobal();

  const { therapists } = config || {};

  // if it is a repeat session, show the selected therapist first
  const options: ITherapist[] = useMemo(() => {
    if (!therapists || !therapists.options) {
      return [];
    }

    return session.isRepeat
      ? [
          session.therapist,
          ...therapists.options.filter((t) => t.value !== session.therapist?.value),
        ].filter((t): t is ITherapist => t !== undefined)
      : therapists.options.filter((t): t is ITherapist => t !== undefined);
  }, [session, therapists]);

  return therapists ? (
    <PreviewContextProvider>
      <InputGroup title={therapists.title}>
        <div className="grid md:grid-cols-2 gap-8 pb-32">
          {options.map((option: ITherapist) => (
            <TherapistCard therapist={option} key={option.value} />
          ))}
        </div>
      </InputGroup>
    </PreviewContextProvider>
  ) : null;
};
