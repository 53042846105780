import { Page } from "./Page";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "context/GlobalContext";
import { LengthSelector, MusicSelector, Progress, StickyNav } from "../components";

export const LengthPage: React.FC = () => {
  const navigate = useNavigate();
  const { session, setSession } = useGlobal();

  useEffect(() => {
    // Scroll to top
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!session.therapist) {
      navigate("/");
      return;
    }

    if (session.issues.length === 0) {
      navigate("/issues");
      return;
    }

    if (!session.issuesDone) {
      const updatedSession = {
        ...session,
        issuesDone: session.issues.length > 0,
      };
      setSession(updatedSession);
    }
  }, [session, setSession, navigate]);

  return (
    <Page>
      <Progress />
      <div className="flex-1">
        <LengthSelector />
        <MusicSelector />
      </div>
      <StickyNav to="/summary" disabled={!session.length || !session.music} />
    </Page>
  );
};
