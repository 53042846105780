import { SessionContext } from "context/SessionContext";
import { useContext } from "react";

export const SessionLog: React.FC = () => {
  const { log } = useContext(SessionContext);
  return (
    <ul className="mb-12">
      {log.map((entry, i) => (
        <li key={i}>{entry}</li>
      ))}
    </ul>
  );
};
