import loadConfig from "data/api";
import { LoadScreen } from "pages/LoadScreen";
import { blankSession } from "data/constants";
import { IConfig, ISessionConfig } from "../types";
import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { sessionExists } from "utilities/functions";

interface GlobalContextProps {
  config?: IConfig;
  setConfig: (config: IConfig) => void;
  session: ISessionConfig;
  setSession: (session: ISessionConfig) => void;
  savedSessions: ISessionConfig[];
  saveSession: (_: ISessionConfig) => void;
  error: string | null;
}

export const GlobalContext = createContext<GlobalContextProps>({
  config: undefined,
  setConfig: () => {},
  session: blankSession,
  setSession: () => {},
  error: null,
  savedSessions: [],
  saveSession: (_: ISessionConfig) => {},
});

export const useGlobal = () => useContext(GlobalContext);

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [config, setConfig] = useState<IConfig | undefined>(undefined);
  const [session, setSession] = useState<ISessionConfig>({ ...blankSession });

  const savedSessions = useMemo(
    () => JSON.parse(localStorage.getItem("sessions") || "[]").reverse(),
    []
  );

  // if there are no saved sessions, we will make the blankSession "active"
  useEffect(() => {
    if (savedSessions.length === 0) {
      setSession({ ...blankSession, active: true });
    }
  }, [savedSessions]);

  const saveSession = useCallback(
    (session: ISessionConfig) => {
      // If a session with the same values exists in localStorage, we don't save it
      // otherwise we save it into the array of up to 3 sessions
      const sessions = JSON.parse(localStorage.getItem("sessions") || "[]");

      // Ensure a matching session doesn't already exist
      // Not sure stringifying is the best way to compare objects - maybe a deep comparison?
      // if (sessionExists(session, sessions)) {
      //   console.log("Equal session already exists in local storage");
      //   return;
      // }

      sessions.push(session);

      // Limit to 3 sessionsc
      const maxSessions = config?.maxSavedSessions || 3;
      // console.log("maxSessions", maxSessions);

      if (sessions.length > maxSessions) {
        sessions.shift();
      }

      localStorage.setItem("sessions", JSON.stringify(sessions));
    },
    [config]
  );

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const configData = await loadConfig();
        setConfig(configData);
      } catch (err: any) {
        setError("Failed to load config");
      } finally {
        setLoading(false);
      }
    };

    console.log("Fetching config");

    fetchConfig();
  }, []);

  if (loading) {
    return <LoadScreen />;
  }

  return (
    <GlobalContext.Provider
      value={{
        config,
        setConfig,
        session,
        setSession,
        savedSessions,
        saveSession,
        error,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
