import * as Tone from "tone";
import { IAudioUrls } from "types";

interface ISessionAudioRequest {
  urls: IAudioUrls;
  delay: number;
  onComplete?: () => void;
}

export class AudioEngine {
  private _id: number;
  private voDelay: number = 3;
  private _isPlaying: boolean = false;
  private startTimestamp: number = 0;
  private voPlayer: Tone.Player | null = null;
  private bgPlayer: Tone.Player | null = null;
  private _onComplete: (() => void) | undefined;
  private voTimeout: NodeJS.Timeout | null = null;
  private urls: IAudioUrls = { voiceover: "", background: "" };

  constructor(id: number) {
    this._id = id;
  }

  public async playSessionAudio(request: ISessionAudioRequest): Promise<void> {
    await Tone.start();

    this._isPlaying = true;

    console.log(`Playing session audio in AudioEngine ${this.id}`, request);

    const { urls, delay, onComplete } = request;

    this.urls = urls;
    this.voDelay = urls.background ? delay : 0;
    this._onComplete = onComplete;

    if (urls.background) {
      this.playBackground(urls.background);
    } else {
      this.playVoiceover(urls.voiceover);
    }
  }

  async playBackground(url?: string): Promise<void> {
    this.startTimestamp = Date.now();

    console.log(
      `Audio Engine ${this._id} starting background audio: `,
      url || this.urls.background
    );

    // await Tone.start();

    if (this.bgPlayer) {
      console.log("Stopping existing background player");
      this.bgPlayer.stop();
      this.bgPlayer.dispose();
    }

    this.bgPlayer = new Tone.Player({
      url: url || this.urls.background,
      autostart: true,
      onload: () => this.playVoiceover(),
      fadeIn: 5,
      volume: -6,
      loop: true,
    }).toDestination();
  }

  async playVoiceover(url?: string, immediate: boolean = false): Promise<void> {
    // await Tone.start();

    if (!this._isPlaying) {
      console.log("Not playing, so don't play voiceover");
      return;
    }

    console.log(
      "Play voiceover",
      url || this.urls.voiceover,
      "elapsed",
      Date.now() - this.startTimestamp,
      "delay is",
      immediate ? 0 : this.voDelay * 1000
    );

    if (this.voTimeout) {
      clearTimeout(this.voTimeout);
    }

    this.voTimeout = setTimeout(
      () => {
        console.log("Created voice player");
        this.voPlayer = new Tone.Player({
          url: url || this.urls.voiceover,
          volume: -3,
          autostart: true,
          onload: () => {
            console.log("Voiceover audio loaded: ", url || this.urls.voiceover);
          },
          onstop: () => {
            console.log("Voiceover audio stopped - running callback now");
            if (this._onComplete && this._isPlaying) {
              this._onComplete();
            }
          },
        }).toDestination();
      },
      immediate ? 0 : this.voDelay * 1000
    );
  }

  stop(): void {
    console.log("Stopping audio");
    if (this.voTimeout) {
      clearTimeout(this.voTimeout);
    }

    if (this.voPlayer) {
      this.voPlayer.stop();
    }

    if (this.bgPlayer) {
      this.bgPlayer.stop();
    }

    this._isPlaying = false;
  }

  start(): void {
    console.log("Starting audio");
    if (this.voPlayer) {
      this.voPlayer.start();
    }

    if (this.bgPlayer) {
      this.bgPlayer.start();
    }
  }

  fadeOut(fadeTime: number = 5, targetLevel: number = -48): void {
    console.log("Fading out background audio", this._id);
    if (this.bgPlayer) {
      this.bgPlayer.volume.rampTo(targetLevel, fadeTime);
      this.bgPlayer.stop(Tone.now() + fadeTime);
    }
  }

  dispose(): void {
    console.log("Disposing audio engine", this._id);
    this.stop();

    if (this.voPlayer) {
      this.voPlayer.dispose();
      this.voPlayer = null;
    }

    if (this.bgPlayer) {
      this.bgPlayer.dispose();
      this.bgPlayer = null;
    }

    if (this.voTimeout) {
      clearTimeout(this.voTimeout);
    }

    console.log("Finished disposing audio engine", this._id, this.voPlayer, this.bgPlayer);
    
    this.urls = { voiceover: "", background: "" };
  }

  get voiceDuration(): number | null {
    if (this.voPlayer && this.voPlayer.buffer) {
      return this.voPlayer.buffer.duration;
    }
    return null;
  }

  get id(): number {
    return this._id;
  }

  get isPlaying(): boolean {
    return this._isPlaying;
  }
  
  set onComplete(callback: () => void) {
    this._onComplete = callback;
  }
}
