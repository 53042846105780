import { Fragment, useContext } from "react";
import { InputGroup } from "./InputGroup";
import { RadioButton } from "./RadioButton";
import { GlobalContext } from "context/GlobalContext";
import { SelectorProps, IOption } from "types";

export const LengthSelector: React.FC<SelectorProps> = ({ muted }) => {
  const context = useContext(GlobalContext);
  const { config } = context;

  if (!config || !context) {
    return null;
  }

  const { lengths } = config;
  const { session, setSession } = context;

  return (
    <Fragment>
      <InputGroup title={lengths.title}>
        {lengths.options.map((option: IOption) => (
          <RadioButton
            key={option.value}
            label={option.label}
            description={option.description}
            value={option.value}
            checked={session.length?.value === option.value}
            onChange={() => setSession({ ...session, length: option })}
            muted={muted}
          />
        ))}
      </InputGroup>
    </Fragment>
  );
};
