import { Button } from "./Button";
import { useContext, useMemo } from "react";
import { SimpleAudio } from "./SimpleAudio";
import { GlobalContext } from "context/GlobalContext";

interface SessionDebugProps {
  onClose: () => void;
}

export const SessionDebug: React.FC<SessionDebugProps> = ({ onClose }) => {
  const { config, session } = useContext(GlobalContext);

  const { therapist, issues, length, music } = session;

  const baseAudioPath = useMemo(() => {
    if (!config) {
      return null;
    }

    const { base, audio } = config.paths;
    return `${base}/${audio}`;
  }, [config]);

  const issuePaths = useMemo(() => {
    if (!config || !therapist || issues.length === 0 || !length) {
      return [];
    }

    // Always have the first issue full length, and the rest short
    return issues.map((issue, index) => {
      return `${baseAudioPath}/issues/${issue.value}_${
        index === 0 ? length?.value : "short"
      }_${therapist?.value}.mp3`;
    });
  }, [config, therapist, issues, length, baseAudioPath]);

  return (
    <div className="px-4">
      {/* <div className="img-container relative mb-4">
            <img
              src={`${config?.paths.images}/${therapist?.image}`}
              alt={therapist?.label}
              className="block h-auto rounded-md max-w-32 md:max-w-full"
            />
          </div> */}
      <h2 className="font-bold mb-4">Session sequence monitor</h2>

      <div className="border-y py-4">
        <h3 className="text-ph-pink font-bold">Beginning</h3>
        <ul>
          <SimpleAudio
            path={`${baseAudioPath}/beginning/beginning_${length?.value}_${therapist?.value}.mp3`}
          />
        </ul>
      </div>

      <div className="border-b py-4">
        <h3 className="text-ph-pink font-bold">Music</h3>
        <ul>
          {["beginning", "space", "issues"].map((section) => (
            <SimpleAudio
              path={`${baseAudioPath}/music/${section}_${music?.value}.mp3`}
              key={section}
            />
          ))}
        </ul>
      </div>

      <div className="border-b py-4">
        <h3 className="text-ph-pink font-bold">Issues</h3>
        <ul>
          {issuePaths.map(
            (path) => {
              return <SimpleAudio path={path} key={path} />;
            },
            [issues]
          )}
        </ul>
      </div>

      <div className="border-b py-4">
        <h3 className="text-ph-pink font-bold">End</h3>
        <ul>
          <SimpleAudio
            path={`${baseAudioPath}/ending/ending_${length?.value}_${therapist?.value}.mp3`}
          />
        </ul>
      </div>

      <div className="py-4">
        <h3 className="text-ph-pink font-bold">Full session data</h3>
        <code className="text-xs">{JSON.stringify(session)}</code>
      </div>

      <Button size="small" variant="secondary" onClick={onClose}>
        Close Debug
      </Button>
    </div>
  );
};
