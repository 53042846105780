// permissible length options of "short" | "medium" | "long"
export enum LengthOption {
    SHORT = "5m",
    MEDIUM = "10m",
    LONG = "20m",
}

export enum SessionStep {
    IDLE = -1,
    BEGINNING = 0,
    SPACE = 1,
    ISSUES = 2,
    ENDING = 3,
    FINISH = 4,
    FORCE_STOP = -2,
}

export type LengthKey = "short" | "medium" | "long";

interface IBaseConfigSection {
    title: string;
    description?: string | string[];
}

interface IConfigSection extends IBaseConfigSection {
    options: IOption[];
}

interface IIssuesConfigSection extends IBaseConfigSection {
    limit: number;
    options: IIssueOption[];
}

interface ITherapistConfigSection extends IBaseConfigSection {
    options: ITherapist[];
}

interface ISpacesConfigSection extends IBaseConfigSection {
    options: ISpaceOption[];
}

interface IMusicConfigSection extends IBaseConfigSection {
    options: IMusicOption[];
}

export interface IAudioUrls {
    voiceover: string;
    background?: string;
}

export interface IConfig {
    delays: Record<LengthKey, number>;
    maxSavedSessions: number;
    paths: {
        home: string;
        base: string;
        audio: string;
        images: string;
    }
    welcome: IBaseConfigSection;
    finish: IBaseConfigSection & {
        calendly: string;
    };
    share: {
        title: string;
        description: string;
        facebook_app_id: string;
        image: string;
    }
    therapists: ITherapistConfigSection;
    spaces: ISpacesConfigSection;
    issues: IIssuesConfigSection;
    lengths: IConfigSection;
    music: IMusicConfigSection;
}

export interface IOption {
    label: string;
    value: string;
    description?: string;
}

export interface ISessionConfig {
    therapist?: ITherapist;
    space?: ISpaceOption;
    issues: IIssueOption[];
    issuesDone?: boolean;
    length?: IOption;
    music?: IMusicOption;
    active?: boolean;
    isRepeat?: boolean;
}

export interface ITherapist extends IOption {
    gender: "male" | "female";
    qualifications?: string;
    image: string;
    bio: string;
    preview?: string;
}

export interface ISpaceOption extends IOption {
    prose?: string;
}

export interface IIssueOption extends IOption {
    prose: string;
    short?: string;
}

export interface IMusicOption extends IOption {
    prose: string;
}

export interface SelectorProps {
    muted?: boolean;
}