import React, { createContext, useState, ReactNode } from "react";

interface PreviewContextProps {
  playingId?: string;
  setPlayingId: (id?: string) => void;
}

export const PreviewContext = createContext<PreviewContextProps>({
  playingId: undefined,
  setPlayingId: () => {},
});

export const PreviewContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [playingId, setPlayingId] = useState<string | undefined>(undefined);

  return (
    <PreviewContext.Provider
      value={{
        playingId,
        setPlayingId,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
};
