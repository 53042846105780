import {
  useMemo,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  createContext,
} from "react";
import { useAudio } from "./SoundContext";
import { useGlobal } from "./GlobalContext";
import { blankSession, stepMap } from "data/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { IAudioUrls, LengthKey, SessionStep } from "../types.d";

interface SessionContextProps {
  current: SessionStep;
  setCurrent: (step: SessionStep) => void;
  running: boolean;
  setRunning: (running: boolean) => void;
  log: string[];
  resetSession: (fullReset?: boolean) => void;
}

export const SessionContext = createContext<SessionContextProps>({
  current: SessionStep.IDLE,
  setCurrent: (_: SessionStep) => {},
  running: false,
  setRunning: (_: boolean) => {},
  log: [],
  resetSession: (_?: boolean) => {},
});

// export const useSession = () => useContext(SessionContext);

export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [current, setCurrent] = useState<SessionStep>(SessionStep.IDLE);
  const [running, setRunning] = useState<boolean>(false);
  const [issueIndex, setIssueIndex] = useState<number>(0);

  const [log, setLog] = useState<string[]>([]);
  const navigate = useNavigate();

  const { getEngineForStep } = useAudio();
  const { pathname } = useLocation();

  const { config, session, setSession } = useGlobal();
  const { therapist, space, issues, length, music } = session;

  const baseAudioPath = useMemo(() => {
    if (!config) {
      return null;
    }

    const { base, audio } = config.paths;
    return `${base}/${audio}`;
  }, [config]);

  const nextStep = useCallback(() => {
    console.log("Moving to next step from", stepMap[current], "to", stepMap[current + 1]);
    setLog((prevLog) => [
      ...prevLog,
      `Moving to next step from ${stepMap[current]} to ${stepMap[current + 1]}`,
    ]);
    const next = current + 1;

    if (next <= SessionStep.FINISH) {
      setCurrent(next);
    }

    // Once it gets past SessionStep.FINISH, the app navigates to a different page
  }, [current, setCurrent, setLog]);

  // When a section is complete, move to the next step
  const onComplete = useCallback(() => {
    console.log("Section complete, moving to next step");
    setLog((prevLog) => [
      ...prevLog,
      "------------------------------",
      `Section complete, moving to next step`,
      "------------------------------",
    ]);
    nextStep();
  }, [nextStep, setLog]);

  // When an issue is complete, move to the next issue
  const onIssueComplete = useCallback(() => {
    console.log("Issue complete, moving to next issue");
    setLog((prevLog) => [...prevLog, `Issue complete, moving to next issue`]);

    const nextIssueIndex = issueIndex + 1;

    if (nextIssueIndex >= issues.length) {
      nextStep();
    } else {
      console.log(
        "Next issue index is",
        nextIssueIndex,
        "issue = ",
        issues[nextIssueIndex].label
      );

      setIssueIndex(issueIndex + 1);
    }
  }, [issueIndex, issues, nextStep, setIssueIndex, setLog]);

  // Get the audio engine for the current step
  const audioEngine = useMemo(
    () => (current >= SessionStep.IDLE ? getEngineForStep(current) : undefined),
    [current, getEngineForStep]
  );

  // Get the audio engine for the previous step
  const previousAudioEngine = useMemo(
    () => (current > SessionStep.BEGINNING ? getEngineForStep(current - 1) : undefined),
    [current, getEngineForStep]
  );

  // Reset the session
  const resetSession = useCallback(
    (fullReset: boolean = false) => {
      console.log("Calling resetSession", fullReset ? " with full reset" : "");

      if (audioEngine) {
        console.log("Dispose audio engine");
        audioEngine.dispose();
      } else {
        console.log("No audio engine to dispose");
      }

      if (previousAudioEngine) {
        console.log("Dispose previous audio engine");
        previousAudioEngine.dispose();
      } else {
        console.log("No previous audio engine to dispose");
      }

      setRunning(false);
      console.log("Setting current step to IDLE");
      setCurrent(SessionStep.IDLE);
      setIssueIndex(0);
      setLog([]);

      if (fullReset) {
        setSession({ ...blankSession });
        navigate("/");
      }
    },
    [
      setCurrent,
      setRunning,
      setIssueIndex,
      setLog,
      audioEngine,
      previousAudioEngine,
      setSession,
      navigate,
    ]
  );

  useEffect(() => {
    if (running && pathname === "/session") {
      const logMsg = `Session audio switch: current step is ${stepMap[current]}`;

      const lengthKey = session?.length?.value as LengthKey;
      const delay = config?.delays[lengthKey] || 3;

      console.log(logMsg);
      setLog((prevLog) => [...prevLog, "------------------------------", logMsg]);

      switch (current) {
        // IDLE
        case SessionStep.IDLE: {
          const logMsg = `Currently idle - going to load the beginning audio`;
          console.log(logMsg);
          setLog((prevLog) => [...prevLog, logMsg]);
          setCurrent(SessionStep.BEGINNING);
          return;
        }

        // BEGINNING
        case SessionStep.BEGINNING: {
          const logMsg = `Loading beginning audio for ${therapist?.value} (${length?.value})`;

          console.log(logMsg);
          setLog((prevLog) => [...prevLog, logMsg]);

          const urls: IAudioUrls = {
            voiceover: `${baseAudioPath}/beginning/beginning_${length?.value}_${therapist?.value}.mp3`,
            background:
              music?.value === "none"
                ? undefined
                : `${baseAudioPath}/music/beginning_${music?.value}.mp3`,
          };

          // Load the beginning therapist audio
          if (audioEngine) {
            console.log(
              "Playing beginning audio - calling playSessionAudio under SessionStep.BEGINNING"
            );
            audioEngine.playSessionAudio({ urls, delay, onComplete });
          }

          return;
        }

        // SPACE
        case SessionStep.SPACE: {
          const logMsg = `Loading space audio for ${therapist?.value}, ${space?.value}`;
          console.log(logMsg);
          setLog((prevLog) => [...prevLog, logMsg]);

          // New URLs for the audio - spaces
          const urls: IAudioUrls = {
            voiceover: `${baseAudioPath}/space/${space?.value}_${length?.value}_${therapist?.value}.mp3`,
            background:
              music?.value === "none"
                ? undefined
                : `${baseAudioPath}/music/space_${music?.value}.mp3`,
          };

          // Load the safe space audio
          if (audioEngine) {
            console.log(
              "Playing space audio - calling playSessionAudio under SessionStep.SPACE"
            );
            audioEngine.playSessionAudio({ urls, delay, onComplete });
            previousAudioEngine?.fadeOut();
          }

          return;
        }

        // ISSUES
        case SessionStep.ISSUES: {
          const currentIssue = issues[issueIndex];
          const issueUrl = `${baseAudioPath}/issues/${currentIssue?.value}_${length?.value}_${therapist?.value}.mp3`;

          const logMsg = `Loading issue audio for ${therapist?.value}, ${currentIssue.value}`;
          console.log(logMsg);
          setLog((prevLog) => [...prevLog, logMsg]);

          // New URLs for the audio - spaces
          if (issueIndex === 0) {
            const urls: IAudioUrls = {
              voiceover: issueUrl,
              background:
                music?.value === "none"
                  ? undefined
                  : `${baseAudioPath}/music/issues_${music?.value}.mp3`,
            };

            // Load the issue audio
            if (audioEngine) {
              previousAudioEngine?.fadeOut();
              console.log(
                "Playing issue audio - calling playSessionAudio under SessionStep.ISSUES"
              );
              audioEngine.playSessionAudio({ urls, delay, onComplete: onIssueComplete });
            }
          } else {
            console.log("Just need to update the voiceover now");
            if (audioEngine) {
              // Update the callback so it is not stale
              audioEngine.onComplete = onIssueComplete;
              audioEngine.playVoiceover(issueUrl); // This should preserve the gap
            }
          }

          return;
        }

        // ENDING
        case SessionStep.ENDING: {
          const logMsg = `Loading ending audio for ${therapist?.value}`;
          console.log(logMsg);
          setLog((prevLog) => [...prevLog, logMsg]);

          const urls: IAudioUrls = {
            voiceover: `${baseAudioPath}/ending/ending_${length?.value}_${therapist?.value}.mp3`,
            background:
              music?.value === "none"
                ? undefined
                : `${baseAudioPath}/music/ending_${music?.value}.mp3`,
          };

          // Load the ending therapist audio
          if (audioEngine) {
            console.log(
              "Playing ending audio - calling playSessionAudio under SessionStep.ENDING"
            );
            audioEngine.playSessionAudio({ urls, delay, onComplete });
            previousAudioEngine?.fadeOut();
          }

          return;
        }

        // FINISH
        case SessionStep.FINISH: {
          navigate("/finish");
          return;
        }

        // NO MATCH
        default: {
          const logMsg = `No audio to load for this step`;
          console.log(logMsg);
          setLog((prevLog) => [...prevLog, logMsg]);
          return;
        }
      }
    } else {
      console.log("Session is not running, checking current step = ", current);

      // if (current !== SessionStep.IDLE) {
      console.log(
        "Current audioEngine is",
        audioEngine,
        "playing?",
        audioEngine?.isPlaying
      );

      if (audioEngine && audioEngine.isPlaying) {
        audioEngine.stop();

        const logMsg = `Stopping audio engine`;
        console.log(logMsg);
        setLog((prevLog) => [...prevLog, logMsg]);
      }

      console.log(
        "Previous audioEngine is",
        previousAudioEngine,
        "playing?",
        previousAudioEngine?.isPlaying
      );
      if (previousAudioEngine && previousAudioEngine.isPlaying) {
        previousAudioEngine.stop();

        const logMsg = `Stopping previous audio engine`;
        console.log(logMsg);
        setLog((prevLog) => [...prevLog, logMsg]);
      }

      // return;
      // }
    }

    // This will never be called?
    // return () => {
    //   if (audioEngine) {
    //     console.log("Cleaning up audio engine");
    //     setLog((prevLog) => [...prevLog, `Cleaning up audio engine`]);
    //     audioEngine.dispose();
    //   }

    //   if (previousAudioEngine) {
    //     console.log("Cleaning up previous audio engine");
    //     setLog((prevLog) => [...prevLog, `Cleaning up previous audio engine`]);
    //     previousAudioEngine.dispose();
    //   }
    // };
  }, [
    audioEngine,
    previousAudioEngine,
    baseAudioPath,
    therapist,
    issues,
    issueIndex,
    length,
    music,
    running,
    setRunning,
    current,
    setCurrent,
    onComplete,
    session,
    config,
    setLog,
    navigate,
    onIssueComplete,
    space?.value,
    pathname,
  ]);

  return (
    <SessionContext.Provider
      value={{
        current,
        setCurrent,
        running,
        setRunning,
        resetSession,
        log,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
