import { Page } from "./Page";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "context/GlobalContext";
import { IssueSelector, Progress, StickyNav } from "../components";

export const IssuesPage: React.FC = () => {
  const navigate = useNavigate();
  const { session } = useGlobal();

  useEffect(() => {
    // Scroll to top
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!session.therapist) {
      navigate("/");
    }
  }, [session, navigate]);

  return (
    <Page>
      <Progress />
      <IssueSelector />
      <StickyNav to="/length" disabled={session.issues.length === 0} />
    </Page>
  );
};
