import { Button } from "./Button";
import { PopupButton } from "react-calendly";
import { ShareFat } from "@phosphor-icons/react";
import { useGlobal } from "context/GlobalContext";

interface FinishButtonsProps {
  onShareClick: () => void;
}

export const FinishButtons: React.FC<FinishButtonsProps> = ({ onShareClick }) => {
  const { config } = useGlobal();
  return (
    <div className="flex gap-4">
      <Button onClick={onShareClick}>
        <ShareFat className="mr-2" size={20} />
        Share
      </Button>

      {config?.finish.calendly && (
        <PopupButton
          className="text-white rounded-md transition duration-300 ease-in-out transform bg-ph-accent hover:bg-ph-accent-dark flex gap-2 items-center justify-center max-w-sm py-3 px-6 text-base"
          url={config.finish.calendly}
          rootElement={document.getElementById("root")!}
          text="Book a session"
        />
      )}
    </div>
  );
};
