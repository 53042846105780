import classNames from "classnames";
import { useLocation } from "react-router-dom";

interface PageProps {
  title?: string;
  children: React.ReactNode | React.ReactNode[];
}

export const Page: React.FC<PageProps> = ({ title, children }) => {
  const { pathname } = useLocation();

  const className = classNames(
    "pt-4 lg:py-12 min-h-screen text-white flex flex-col",
    {
      "bg-ph-purple": pathname !== "/session",
      "bg-black": pathname === "/session",
    }
  );

  return (
    <div className={className}>
      <div className="container max-w-3xl mx-auto flex-1 flex flex-col">
        {title && <h1 className="text-2xl font-bold mb-8 px-4">{title}</h1>}
        {children}
      </div>
    </div>
  );
};
