import { ISessionConfig, ITherapist } from "types";

const mockTherapist: ITherapist = {
  label: "Simon",
  value: "simon",
  gender: "male",
  image: "dr-simon.jpg",
  bio: "Simon is a highly experienced therapist who has been helping people for over 20 years. He has a calm, reassuring voice and a gentle manner.",
};

const mockData: ISessionConfig = {
  therapist: {
    ...mockTherapist,
  },
  space: {
    label: "Forest",
    description:
      "A beautiful, natural and peaceful forest glade, with a lake and perfect hammock.",
    value: "forest",
  },
  issues: [
    {
      label: "Happiness - accept how awesome you are",
      description:
        "Accept that you are the most important person in your life. Like and love yourself more, because you're unique and incredible.",
      value: "selfworth",
      prose: "boosting your sense of self-worth",
    },
    // {
    //   label: "Lose weight",
    //   value: "weight_loss",
    //   prose: "wanting to lose weight",
    // },
    // {
    //   label: "Identity issues",
    //   value: "healing",
    //   prose: "having identity issues",
    // },
  ],
  issuesDone: true,
  music: {
    label: "Theme from Chillsville",
    description: "Our signature, chilled, modern and ambient soundtrack.",
    value: "default",
    prose: "with our beautiful Theme music",
  },
  length: {
    label: "Short",
    description: "Ideally under ten minutes",
    value: "short",
  },
};

const mockDataSteve: ISessionConfig = {
  ...mockData,
  therapist: {
    label: "Steve",
    value: "steve",
    gender: "male",
    image: "dr-steve.jpg",
    bio: "Steve is a witch doctor - proceed with caution.",
  },
};

const mockDataErika: ISessionConfig = {
  ...mockData,
  therapist: {
    label: "Erika",
    value: "erika",
    image: "dr-erika.jpg",
    gender: "female",
    bio: "Erika is a warm, empathetic therapist who has a gift for making people feel at ease. She has a soothing voice and a caring approach.",
  },
};

export const blankSession = {
  therapist: undefined,
  space: undefined,
  issues: [],
  length: undefined,
  active: false,
};

export const allMockData: Record<string, ISessionConfig> = {
  simon: { ...mockData },
  steve: { ...mockDataSteve },
  erika: { ...mockDataErika },
};

export const stepMap = ["Beginning", "Space", "Issues", "End", "Finish"];
