import { Page } from "./Page";
import { ISessionConfig } from "types";
import { Link, useNavigate } from "react-router-dom";
import { allMockData } from "data/constants";
import { Check } from "@phosphor-icons/react";
import { GlobalContext } from "../context/GlobalContext";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { TherapistSelector, StickyNav, Button, SavedSessions } from "../components";

export const Home: React.FC = () => {
  const { session, setSession, savedSessions, config } = useContext(GlobalContext);
  const { therapist } = session;
  const [usingMock, setUsingMock] = useState<boolean>(false);
  const navigate = useNavigate();

  const populateSession = useCallback(
    (therapist: string) => {
      const mockData = allMockData[therapist] as ISessionConfig;
      setSession({ ...mockData });
      setUsingMock(true);
    },
    [setSession]
  );

  // useEffect(() => {
  //   populateSession("simon");
  // }, [populateSession]);

  // useEffect(() => {
  //   if (session.therapist?.value === "simon") {
  //     navigate("/summary");
  //   }
  // }, [session, navigate]);

  // When a session is selected here, we should navigate straight to /summary
  // useEffect(() => {
  //   if (session.active && session.isRepeat) {
  //     console.log("Session is active, navigating to /summary");
  //     navigate("/summary");
  //   }
  // }, [session, navigate]);

  if (!config) {
    return null;
  }

  const { welcome } = config;

  return (
    <Page title={welcome.title}>
      <p className="text-sm md:text-base mb-4 px-4">{welcome.description}</p>

      <Fragment>
        {savedSessions.length > 0 && !session.active && <SavedSessions />}

        {session.active && <TherapistSelector />}

        <div className="flex px-4 gap-4 mb-32 flex-col items-center justify-center">
          <div className="flex gap-4">
            {["Simon", "Erika", "Steve"].map((therapist) => (
              <Button
                key={therapist}
                variant={
                  session?.therapist?.value === therapist.toLowerCase()
                    ? "primary"
                    : "secondary"
                }
                size="small"
                onClick={() => populateSession(therapist.toLowerCase())}
              >
                Use {therapist}
                {session?.therapist?.value === therapist.toLowerCase() && <Check />}
              </Button>
            ))}
          </div>
          {usingMock && <Link to="/session">Jump to session</Link>}
        </div>
      </Fragment>

      <StickyNav to="/space" disabled={!therapist} label="Next" hideBack />
    </Page>
  );
};
