import { useMemo } from "react";
import { Button } from "./Button";
import { ITherapist } from "types";
import classNames from "classnames";
import { Check } from "@phosphor-icons/react";
import { SpeechPreview } from "./SpeechPreview";
import { useGlobal } from "context/GlobalContext";

interface ITherapistCardProps {
  therapist: ITherapist;
}

export const TherapistCard: React.FC<ITherapistCardProps> = ({ therapist }) => {
  const { label, image, bio, qualifications } = therapist;
  const { session, setSession, config } = useGlobal();

  const imagePath = useMemo(
    () => `${config?.paths.base}/${config?.paths.images}`,
    [config]
  );

  const previewUrl = useMemo(() => {
    if (!config || !therapist) {
      return undefined;
    }

    return `${config?.paths.base}/${config?.paths.audio}/preview/preview_${therapist.value}.mp3`;
  }, [therapist, config]);

  const { therapist: selectedTherapist } = session;

  const className = classNames(
    "border-2 p-4 md:p-8 border-white rounded-md bg-white bg-opacity-10 transition-all duration-200",
    {
      "border-opacity-0": selectedTherapist !== therapist,
      "border-opacity-50": selectedTherapist === therapist,
    }
  );

  const isSelected = useMemo(
    () => selectedTherapist?.value === therapist.value,
    [selectedTherapist, therapist]
  );

  return (
    <div className={className}>
      <div className="therapist-card flex flex-row-reverse md:flex-col gap-4 items-start h-full">
        <div className="img-container relative mb-4">
          <img
            src={`${imagePath}/${image}`}
            alt={label}
            className="block  h-auto rounded-md max-w-32 md:max-w-full"
          />
          <div className="absolute -bottom-12 md:bottom-2 right-0 md:right-2">
            {previewUrl && <SpeechPreview url={previewUrl} />}
          </div>
        </div>
        <div className="radio-info flex-1 flex flex-col gap-4 justify-between">
          <div className="flex-1">
            <h3 className="font-bold text-xl">{label}</h3>
            {qualifications && <p className="text-sm mb-2 opacity-60">{qualifications}</p>}
            <p className="text-sm mb-4">{bio}</p>
          </div>
          <Button
            size="small"
            onClick={() => setSession({ ...session, therapist })}
            disabled={isSelected}
          >
            {!isSelected && "Choose "}
            {therapist.label}
            {isSelected && <Check size={16} weight="bold" />}
          </Button>
        </div>
      </div>
    </div>
  );
};
