import React from "react";
import {
  Home,
  TherapistPage,
  SpacePage,
  IssuesPage,
  LengthPage,
  SummaryPage,
} from "./pages";
import { FinishPage } from "pages/FinishPage";
import { SessionPage } from "pages/SessionPage";
import { SessionProvider } from "context/SessionContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const AppRouter: React.FC = () => (
  <Router>
    <SessionProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/therapist" element={<TherapistPage />} />
        <Route path="/space" element={<SpacePage />} />
        <Route path="/issues" element={<IssuesPage />} />
        <Route path="/length" element={<LengthPage />} />
        <Route path="/summary" element={<SummaryPage />} />
        <Route path="/session" element={<SessionPage />} />
        <Route path="/finish" element={<FinishPage />} />
      </Routes>
    </SessionProvider>
  </Router>
);

export default AppRouter;
