import classNames from "classnames";

interface CheckboxProps {
  label: string;
  description?: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  description,
  checked,
  disabled,
  onChange,
}) => {
  const className = classNames("component checkbox", {
    "opacity-50": disabled,
  });

  return (
    <div className={className}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        {label}
      </label>
      {description && <p className="text-sm xl:text-base ml-8 opacity-60 mt-1 mb-2">{description}</p>}
    </div>
  );
};
