import classNames from "classnames";
import { Icon } from "@phosphor-icons/react";

interface InputGroupProps {
  title?: string;
  icon?: Icon;
  muted?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const InputGroup: React.FC<InputGroupProps> = ({
  title,
  icon: IconComponent,
  muted,
  children,
}) => {
  const classNm = classNames("flex flex-col space-y-2 flex-1 px-4 mb-8", {
    "opacity-30 pointer-events-none": muted,
  });

  return (
    <div className={classNm}>
      <div className="header flex gap-2">
        {title && <h2 className="text-xl text-ph-pink mb-2">{title}</h2>}
      </div>
      {children}
    </div>
  );
};
