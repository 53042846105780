import { Page } from "./Page";
import { SessionStep } from "types.d";
import { allMockData, blankSession } from "data/constants";
import { useNavigate } from "react-router-dom";
import { useAudio } from "context/SoundContext";
import { useGlobal } from "context/GlobalContext";
import { StickyNav } from "../components/StickyNav";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ShareModal } from "../components/ShareModal";
import { doStringReplacements } from "utilities/functions";
import { FinishButtons } from "../components/FinishButtons";

export const FinishPage: React.FC = () => {
  const { session, config, setSession } = useGlobal();
  const [showShareOptions, setShowShareOptions] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSession({ ...allMockData.steve });
  }, [setSession]);

  useEffect(() => {
    if (!session.therapist) {
      navigate("/");
      return;
    }
  }, [session, navigate]);

  const { getEngineForStep } = useAudio();

  useEffect(() => {
    const engine = getEngineForStep(SessionStep.ENDING);
    if (engine) {
      engine.fadeOut(120, -96);
    }
  }, [getEngineForStep]);

  const titleText = useMemo(() => {
    if (config) {
      return doStringReplacements(config.finish.title, session);
    }
    return `We hope you enjoyed your session with ${session.therapist?.label}`;
  }, [config, session]);

  return (
    <Page title={titleText}>
      <div className="px-4 flex flex-col md:flex-row gap-8 h-full">
        <div className="flex-1 flex flex-col justify-between">
          {/* Text */}
          <div className="flex-grow">
            {config?.finish.description &&
              (config?.finish.description as string[]).map((d, i) => (
                <p key={i} className="mb-4">
                  {d}
                </p>
              ))}
          </div>

          {/* Buttons UI  */}
          <FinishButtons onShareClick={() => setShowShareOptions(!showShareOptions)} />
        </div>

        <div className="flex-1 flex items-center">
          <img
            className="sm:max-w-xs block rounded-md h-full object-cover"
            src={`${config?.paths.base}/${config?.paths.images}/${session.therapist?.image}`}
            alt={session.therapist?.label}
          />
        </div>
      </div>
      <ShareModal isOpen={showShareOptions} onClose={() => setShowShareOptions(false)} />
      <StickyNav restartMode />
    </Page>
  );
};
