import { Page } from "./Page";
import { SessionStep } from "types.d";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "context/GlobalContext";
import { SessionContext } from "context/SessionContext";
import { Button, Overview, StickyNav } from "../components";

export const SummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const { session } = useGlobal();
  const { setCurrent } = useContext(SessionContext);

  const context = useContext(SessionContext);

  useEffect(() => {
    // Scroll to top
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    console.log(
      "SummaryPage dependency update, running effect, setting current to FORCE_STOP"
    );
    setCurrent(SessionStep.FORCE_STOP);

    return () => {
      console.log("SummaryPage dependency update, running cleanup");
    };
  }, [setCurrent]);

  useEffect(() => {
    if (!session.therapist) {
      navigate("/");
    }

    if (!session.space) {
      navigate("/space");
    }

    if (session.issues.length === 0) {
      navigate("/issues");
    }

    if (!session.length || !session.music) {
      navigate("/length");
    }
  }, [session, navigate]);

  if (
    !session.therapist ||
    !session.space ||
    session.issues.length === 0 ||
    !session.length ||
    !session.music
  ) {
    return null;
  }

  return (
    <Page>
      <Overview>
        <Button onClick={() => navigate("/session")}>Let’s get started</Button>
      </Overview>
      <StickyNav
        to="/session"
        disabled={session.issues.length === 0}
        className="static mt-8 md:fixed md:mt-0 bottom-0 left-0 w-full p-4 py-8 bg-black transition duration-300"
      />
    </Page>
  );
};
