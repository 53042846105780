import { ISessionConfig } from "types";

export const doStringReplacements = (text: string, session: ISessionConfig): string => {
  if (session.therapist) {
    return text.replace(/{therapist}/g, session.therapist.label);
  }

  return text;
};

// https://api.whatsapp.com/send/?text=https%3A%2F%2Fyoutu.be%2FfOAIrUZbOwo%3Fsi%3DbvdYjasyDmwBXMSg&type=custom_url&app_absent=0

export const sessionExists = (
  session: ISessionConfig,
  sessions: ISessionConfig[]
): boolean => {
  // console.log("Checking if session exists", session, sessions);

  return sessions.some((s) => {
    // console.log("Checking sessions for match", s, session);

    const basicsMatch =
      s.therapist?.value === session.therapist?.value &&
      s.space?.value === session.space?.value &&
      s.music?.value === session.music?.value &&
      s.length?.value === session.length?.value;

    if (!basicsMatch) {
      // console.log("Basic match failed", s, session);
      return false;
    }

    // console.log("Now checking issues", s.issues, session.issues);

    // Now check the issues - they might be in a different order
    return (session.issues || []).every((issue) =>
      s.issues.some((sIssue) => sIssue.value === issue.value)
    );
  });
};
