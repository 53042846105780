import { InputGroup } from "./InputGroup";
import { RadioButton } from "./RadioButton";
import { Fragment, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { SelectorProps, IMusicOption } from "types";

export const MusicSelector: React.FC<SelectorProps> = ({ muted }) => {
  const context = useContext(GlobalContext);
  const { config } = context;

  if (!config || !context) {
    return null;
  }

  const { music } = config;
  const { session, setSession } = context;

  return (
    <Fragment>
      <InputGroup title={music.title}>
        {music.options.map((option: IMusicOption) => (
          <RadioButton
            key={option.value}
            label={option.label}
            description={option.description}
            value={option.value}
            checked={session.music?.value === option.value}
            onChange={() => setSession({ ...session, music: option })}
            muted={muted}
          />
        ))}
      </InputGroup>
    </Fragment>
  );
};
