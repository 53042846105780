import { Button } from "./Button";
import { useContext } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "context/SessionContext";
import { ArrowArcLeft, CaretLeft, CaretRight } from "@phosphor-icons/react";

interface StickyNavProps {
  to?: string;
  label?: string;
  disabled?: boolean;
  hideBack?: boolean;
  className?: string;
  restartMode?: boolean;
}

export const StickyNav: React.FC<StickyNavProps> = ({
  to,
  label,
  disabled,
  hideBack,
  className,
  restartMode,
}) => {
  const navigate = useNavigate();
  const { resetSession } = useContext(SessionContext);

  const classNm =
    className ||
    classNames("fixed bottom-0 left-0 w-full p-4 py-8 bg-black transition duration-300", {
      "translate-y-0": !disabled,
      "translate-y-full": disabled && hideBack,
    });

  return (
    <div className={classNm}>
      <div className="container max-w-3xl mx-auto grid grid-cols-2 gap-8 px-4">
        <div className="flex">
          {restartMode && (
            <Button onClick={() => resetSession(true)} variant="secondary">
              <ArrowArcLeft size={24} />
              Start again
            </Button>
          )}
          {!restartMode && (
            <Button onClick={() => navigate(-1)} variant="secondary">
              <CaretLeft size={24} />
              Back
            </Button>
          )}
        </div>
        {!restartMode && to && (
          <Button onClick={() => navigate(to)} disabled={disabled}>
            {label || "Next"} <CaretRight size={24} />
          </Button>
        )}
      </div>
    </div>
  );
};
